// Angular Library Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Custom Imports
import { FillHeightModule } from '../fill-height/fill-height.module';
import { SortIconComponent } from '../common/directives/sort-icon/sort-icon.component';
// Third Party Library Imports
import { TableModule } from 'node_modules/primeng/table';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    SortIconComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    CalendarModule,
    FillHeightModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    TableModule,
    CalendarModule,
    FillHeightModule,
    SortIconComponent
  ]
})
export class SharedModule {

}
