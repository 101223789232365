// Angular Library Imports
import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
// Custom Service Imports
import { CommonService } from '../services/common.service';
import { HttpRequestService } from '../../../http-handler/http-request.handler';
import { HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Custom Event Handling
  @Output() doSidebarCallapse = new EventEmitter<Boolean>();
  private getNotificationsSubscription: Subscription;
  private updateNotificationSubsciption: Subscription;
  /**
   * Public Data Members
   */
  public sideCollapse: boolean = false;
  public isCollapsed: boolean = false;
  public isLoading: boolean = false;
  public userName: string;
  public pageNo = 1;
  public notificationList: any = {
    'notifications': [],
    'notificationCount': 0,
    'notificationList': 0,
    'unreadnotificationCount': 0
  };
  public enable_next: any;
  constructor(
    private commonService: CommonService,
    private cookie: CookieService,
    private router: Router,
    private toastrService: ToastrService,
    private httpRequestService: HttpRequestService
  ) { }

  @HostListener('scroll', ['$event'])
  onScrollNotifications(event) {
    if (event.srcElement.scrollHeight <= Math.round(event.srcElement.scrollTop + event.srcElement.clientHeight)) {
    if (this.enable_next === true && this.getNotificationsSubscription === undefined && this.pageNo > 1) {
      // this.getNotifications();
    }
  }
  }



  /**
   * Get User Roles For Current Page
   */
  // private getNotifications(): void {
  //   // Unsubscribe previous api call
  //   if (this.getNotificationsSubscription === undefined) {
  //   // Parameters
  //   const params = { page: this.pageNo };
  //   // Call the offer list from API
  //   this.getNotificationsSubscription = this.httpRequestService.httpRequest('get', 'master/getnotifications', params).subscribe(
  //     (response: HttpResponse<any>) => {
  //       if (response['code'] === 200) {
  //         this.notificationList['notificationCount'] = response['data']['notificationCount'];
  //         this.notificationList['notificationCount'] = response['data']['notificationCount'];
  //         this.notificationList['unreadnotificationCount'] = response['data']['unreadnotificationCount'];
  //         response['data']['notifications'].forEach(notifcationInfo => {
  //           this.notificationList['notifications'].push(notifcationInfo);
  //         });
  //         this.enable_next = response['data']['enable_next'];
  //         if (this.enable_next === true) {
  //           this.pageNo++;
  //         }
  //       }
  //       this.getNotificationsSubscription = undefined;
  //     },
  //     (error: HttpResponse<any>) => {
  //       console.error(error);
  //       this.getNotificationsSubscription = undefined;
  //     }
  //   );
  //   }
  // }

  public updateNotification(notfication): void {
    if (notfication['is_read'] === false) {
      if (this.updateNotificationSubsciption === undefined) {
        const params = {};
        params['id'] = notfication['id'];
        params['is_read'] = true;
        this.updateNotificationSubsciption = this.httpRequestService.httpRequest('put', 'master/updatenotificationdata', params).subscribe(
          (response: HttpResponse<any>) => {
            if (response['code'] === 200) {
                this.pageNo = 1;
                this.enable_next = false;
                this.notificationList['notifications'] = [];
            // this.getNotifications();
              this.router.navigate(['/notification']);
            }
            this.updateNotificationSubsciption = undefined;
          },
          (error: HttpResponse<any>) => {
            console.error(error);
            this.updateNotificationSubsciption = undefined;
          }
        );
      }
    }
  }

  ngOnInit() {
    // this.getNotifications();
    this.userName = localStorage.getItem('username');
    this.commonService.set('username', this.userName);
    this.commonService.eventObservable.subscribe(
      (event) => {
        if (event.event === 'isSessionExpired') {
          // Remove All Cookie Data
          this.cookie.delete('session');
          this.cookie.deleteAll();
          this.router.navigate(['/login']);
        }
      }
    );
  }

  public goToNotification(): void {
    this.router.navigate(['/notification']);
  }
  /**
   * Logout And Remove Cookie
   */
  public logout(): void {
    // Redirect to login URL
    // window.location.href = environment.baseUrl;
    // Remove All Cookie Data
    this.cookie.delete('session');
    this.cookie.deleteAll();
    this.router.navigate(['/login']);
    // Call Logout API
    this.httpRequestService.httpRequest('get', 'user/logout').subscribe(
      () => {
        // Show success toast message
        this.toastrService.success('Logout Successful', 'SUCCESS');
        localStorage.removeItem('sidebarConfig');
        // Remove authKey from CommonService
        this.commonService.remove('session');
      },
      (error: HttpResponse<any>) => {
        console.error(error);
        // Show success toast message
        this.toastrService.error('Server Error', 'ERROR');
      }
    );
  }

  /**
   * Toggle State Of Sidebar Collapse
   */
  public toggleState() {
    this.isCollapsed = !this.isCollapsed;
    const bool = this.sideCollapse;
    this.sideCollapse = bool === false ? true : false;
    // Emit Event
    this.doSidebarCallapse.emit(this.sideCollapse);
    this.commonService.setEvent('isSidebarCollapsed', this.sideCollapse);
  }
}
