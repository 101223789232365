import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './common/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('collapse', [
      state('open', style({
        opacity: '1',
        display: 'block',
        transform: 'translate3d(0, 0, 0)'
      })),
      state('closed',   style({
        opacity: '0',
        display: 'none',
        transform: 'translate3d(0, -100%, 0)'
      })),
      transition('closed => open', animate('200ms ease-in')),
      transition('open => closed', animate('100ms ease-out'))
    ])
  ]
})
export class AppComponent implements OnInit, AfterViewChecked {
  /**
   * Public Data Members
   */
  public title: String = 'app';
  public isCallapsed: Boolean = false;
  public currentRoute: any = '/login';

  constructor(
    private router: Router,
    private cookie: CookieService,
    private commonService: CommonService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.router.events.subscribe(
      (event: any) => {
        // console.log(this.router.url);
        if (event instanceof NavigationEnd) {
          this.currentRoute = this.router.url;
          const authKey = this.cookie.get('session');
          if (!authKey && this.router.url !== '/login') {
            // Redirect to login URL
            // window.location.href = environment.baseUrl;
            this.router.navigate(['/login']);
          }
        }
      }
    );
  }


  /**
   * Angular Lifecycle Event For Component  View Checking
   */
  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * Toggle Callapse
   * @param event Pass Event Object
   */
  public toggleCollapse(event: any): void {
    this.isCallapsed = event;
  }

  /**
   * Angular Lifecycle Event For Component Initialization
   */
  public ngOnInit(): void {
    const session = this.cookie.get('session');
    if (session) {
      this.commonService.set('session', session);
    }
  }
}
