import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.scss']
})
export class SortIconComponent {

  @Input() fieldName: string;
  @Input() sortEvent: {sortField: string, sortOrder: string};

  constructor() { }

}
