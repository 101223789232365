// Angular Library Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// Our Custom Imports
import { environment } from '../environments/environment';


@Injectable()
export class HttpRequestService {

    constructor(private httpClient: HttpClient) {
    }

    /**
     * Generic Http Request Function to call all APIs
     * @param requestType Like get, post, delete
     * @param apiCall API call string
     * @param data Pass data in the form of Object
     */
    public httpRequest(requestType: string, apiCall: string, data?: any): Observable<any> {
        if (data && Object.prototype.toString.call(data) === '[object Object]') {
          const allParams = Object.keys(data);
          for (const param of allParams) {
            if (data[param] === undefined || data[param] === null || data[param] === '') {
              delete data[param];
            }
            if (data[param] && Object.prototype.toString.call(data[param]) === '[object String]') {
              data[param] = data[param].trim().replace(/[\\'"]+/g, '');
              if (data[param] === 'true') {
                data[param] = true;
              }
              if (data[param] === 'false') {
                data[param] = false;
              }
              if (data[param] === 'undefined') {
                delete data[param];
              }
            }
          }
        }
        // if (data && Object.prototype.toString.call(data) === '[object FormData]') {
        //   for (const element of data.entries()) {
        //     const key = element[0];
        //     const val = element[1];
        //     data.delete(key);
        //     data.set(key, val.trim().replace(/[\\'"]+/g, ''));
        //     // pair[1] = pair[1].trim().replace(/[\\'"]+/g, '');
        //   }
        // }
        // Get Request
        // If get request has data to be passed then pass it into third parameter of this function. Like 'page=1'
        if (requestType === 'get' && data !== undefined) {
            return this.httpClient.get(`${environment.origin}/${apiCall}`, {params: data});
        }

        if (requestType === 'get' && data === undefined) {
            return this.httpClient.get(`${environment.origin}/${apiCall}`);
        }
        if (requestType === 'post' && data !== undefined && apiCall === 'Login') {
            return this.httpClient.post(`${environment.origin}/${apiCall}`, data, { observe: 'response' });
        }

        if (requestType === 'post' && data !== undefined && apiCall !== 'Login') {
            return this.httpClient.post(`${environment.origin}/${apiCall}`, data);
        }

        if (requestType === 'put' && data !== undefined) {
            return this.httpClient.put(`${environment.origin}/${apiCall}`, data);
        }

    }
}
