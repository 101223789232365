import { HostListener, Directive, ElementRef, Input, Output, EventEmitter, AfterContentInit, OnInit } from '@angular/core';
import { CommonService } from '../common/services/common.service';

@Directive({
selector: '[appFillHeight]',
})
export class FillHeightDirective implements OnInit, AfterContentInit {

  @Input() footerElement = null;
  @Output() tableHeight = new EventEmitter();
  @Output() scrollUp = new EventEmitter();
  @Output() scrollDown = new EventEmitter();

  constructor(
    private el: ElementRef,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.calculateAndSetElementHeight();
    // Observe Event For Reset Scroll Of Table
    this.commonService.eventObservable.subscribe(
      (event) => {
        // Check For The Event Name
        if (event.event === 'TableScrollReset') {
          // Get Table Element From DOM
          const table = document.getElementsByClassName('ui-table-scrollable-body');
          // Reset To TOP On Zero Pixel
          if (table.length > 0) {
            table[0].scrollTop = 0;
          }
        }
      }
    );
  }

  /**
   * Angular Lifecycle Event For Content Initialisation
   */
  ngAfterContentInit(): void {
    // Wait For Content To Be Fully Loaded
    setTimeout(() => {
      this.calculateAndSetElementHeight();
      // Get Table Element
      const table = document.getElementsByClassName('ui-table-scrollable-body');
      // Register Event Listener
      if (table.length > 0) {
        table[0].addEventListener('scroll', (event) => {
          // console.log(event.srcElement.scrollHeight, event.srcElement.scrollTop, event.srcElement.clientHeight);
          // Emit Scroll Reached To Bottom
          if (event.srcElement.scrollHeight <= Math.round(event.srcElement.scrollTop + event.srcElement.clientHeight)) {
            this.scrollDown.emit();
          }
          // Emit Scroll Reached To Top
          if (event.srcElement.scrollTop === 0) {
            this.scrollUp.emit();
          }
        });
      }
    }, 2000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateAndSetElementHeight();
    this.ngAfterContentInit();
  }

  private calculateAndSetElementHeight() {
    this.el.nativeElement.style.overflow = 'auto';
    const windowHeight = window.innerHeight;
    const elementOffsetTop = this.getElementOffsetTop();
    // const elementMarginBottom = this.el.nativeElement.style.marginBottom;
    const footerElementMargin = this.getfooterElementMargin();

    this.el.nativeElement.style.height = windowHeight - footerElementMargin - elementOffsetTop - 70 + 'px';

    this.tableHeight.emit(this.el.nativeElement.style.height);
  }

  private getElementOffsetTop() {
    return this.el.nativeElement.getBoundingClientRect().top;
  }

  private getfooterElementMargin() {
    if (!this.footerElement) { return 0; }
    const footerStyle = window.getComputedStyle(this.footerElement);
    return parseInt(footerStyle.height, 10);
  }

}
