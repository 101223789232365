// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // baseUrl: 'https://testautowebapp.azurewebsites.net/',
  production: false,
  // origin: 'https://testauto-api.azurewebsites.net',
  // origin: 'https://usedcars-cem.alghanimauto.com/api/',
  // origin: 'https://apidev.usedcarskuwait.com',
  origin: 'https://api.alghanimused.com',
  
  // origin: 'https://usedcars-prod-dev.alghanim-prod-ase.p.azurewebsites.net/api',
  // origin: 'http://10.1.1.64:4003/',

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
