import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// import { environment } from '../environments/environment';
@Injectable()
export class RouteGuardService implements CanActivate, CanActivateChild {

  constructor(public cookie: CookieService, public router: Router) {}

  canActivate(): boolean {
    if (!this.cookie.get('session')) {
      // this.router.navigate(['login']);
      // window.location.href = environment.baseUrl;
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
