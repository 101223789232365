// Angular Library Imports
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { HttpRequestService } from '../../../http-handler/http-request.handler';
import { CommonService } from '../services/common.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  // Callapse Notification Input
  @Input() callapse: Boolean;
  // To hold current route
  @Input() currentRoute: any;
  private getUserRolesForCurrentPageSubscription: Subscription;

  /**
   * Public Data Members
   */
  public currentUserPath: string = '';
  public envOrigin: any;
  public menu: any = [
    {
      'menu_name': 'Accessory',
      'link': '/accessory',
      'img': 'assets/imgs/sidebar-icons/Accessory.svg',
      'img_active': 'assets/imgs/sidebar-icons/Accessory-w.svg',
      'menu_item_list': [
        {
          'menu_item_name': 'Accessory Category Master',
          'link': '/accessory/accessory-category-master',
        },
        {
          'menu_item_name': 'Accessory Listing',
          'link': '/accessory/accessory-listing',
        },
        {
          'menu_item_name': 'Campaign Listing',
          'link': '/accessory/campaign-listing',
        },
        {
          'menu_item_name': 'Enquiry Listing',
          'link': '/accessory/enquiry-listing',
        }
      ]
    },
    {
      'menu_name': 'Other Master',
      'link': '/other-master',
      'img': 'assets/imgs/sidebar-icons/Others-Master.svg',
      'img_active': 'assets/imgs/sidebar-icons/Others-Master-w.svg',
      'menu_item_list': [
        {
          'menu_item_name': 'Area Listing',
          'link': '/other-master/area-listing',
        },
        // {
        //   'menu_item_name': 'Bank & Emi Listing',
        //   'link': '/other-master/bank-and-emi-listing',
        // },
        {
          'menu_item_name': 'Contact Listing',
          'link': '/other-master/contact-listing',
        },
        {
          'menu_item_name': 'Designation Listing',
          'link': '/other-master/designation-listing',
        },
        {
          'menu_item_name': 'From to Price Listing',
          'link': '/other-master/from-to-price-listing',
        },
        // {
        //   'menu_item_name': 'Response Status Listing',
        //   'link': '/other-master/response-status-listing',
        // },
        // {
        //   'menu_item_name': 'Service Booking Status Listing',
        //   'link': '/other-master/service-booking-status-listing',
        // },
        {
          'menu_item_name': 'Year Listing',
          'link': '/other-master/year-listing',
        },
        {
          'menu_item_name': 'Lookup Listing',
          'link': '/other-master/lookup-listing',
        },
        {
          'menu_item_name': 'KM Listing',
          'link': '/other-master/km-listing',
        },
        {
          'menu_item_name': 'Category Listing',
          'link': '/other-master/category-listing',
        },

        {
          'menu_item_name': 'Color Listing',
          'link': '/other-master/color-listing',
        },
        {
          'menu_item_name': 'Country Code Listing',
          'link': '/other-master/country-code-flag-listing',
        },
        {
          'menu_item_name': 'Notification Listing',
          'link': '/other-master/notification-listing',
        },
        {
          'menu_item_name': 'Social Media Listing',
          'link': '/other-master/social-media',
        },
        {
          'menu_item_name': 'Brand Listing',
          'link': '/other-master/brand-listing',
        },
        {
          'menu_item_name': 'Model Listing',
          'link': '/other-master/model-listing',
        }
      ]
    },
    {
      'menu_name': 'New Car Section',
      'link': '/new-car-section',
      'img': 'assets/imgs/sidebar-icons/Section-Module.svg',
      'img_active': 'assets/imgs/sidebar-icons/Section-Module-w.svg',
      'menu_item_list': [
        {
          'menu_item_name': 'Specification Heading Master',
          'link': '/new-car-section/specification-heading-master',
        },
        {
          'menu_item_name': 'Specification Master',
          'link': '/new-car-section/specification-master',
        },
        {
          'menu_item_name': 'Model Car listing',
          'link': '/new-car-section/model-car-listing',
        },
        {
          'menu_item_name': 'Payment Request Listing',
          'link': '/new-car-section/payment-reuest-listing',
        },
        {
          'menu_item_name': 'Test Drive Listing',
          'link': '/new-car-section/test-drive-listing',
        },
        {
          'menu_item_name': 'Variant Car Listing',
          'link': '/new-car-section/variant-car-listing',
        }
      ]
    },
    {
      'menu_name': 'Role Mangement',
      'link': '/role-management',
      'img': 'assets/imgs/sidebar-icons/Role-Management.svg',
      'img_active': 'assets/imgs/sidebar-icons/Role-Management-w.svg',
      'menu_item_list': [
        {
          'menu_item_name': 'Role Listing',
          'link': '/role-management/role-listing',
        },
        {
          'menu_item_name': 'Map Role',
          'link': '/role-management/role-map',
        }
      ]
    },
    {
      'menu_name': 'User Master',
      'img': 'assets/imgs/sidebar-icons/Role-Management.svg',
      'img_active': 'assets/imgs/sidebar-icons/Role-Management-w.svg',
      'link': '/user-master'
    },
    // {
    //   'menu_item_name': 'Kromozone Accessory Master',
    //   'link': '/kromozone-management',
    //   'img': 'assets/imgs/sidebar-icons/Management.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Management-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Accessory Listing',
    //       'link': '/kromozone-management/accessory-listing',
    //
    //     },
    //     {
    //       'menu_item_name': 'Campaign Listing',
    //       'link': '/kromozone-management/campaign-listing',
    //
    //     }
    //   ]
    // },
    // {
    //   'menu_item_name': 'Kromozone Accessory',
    //   'link': '/accessory-master',
    //   'img': 'assets/imgs/sidebar-icons/Management.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Management-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Accessory Listing',
    //       'link': '/accessory-master/accessory-listing',
    //
    //     },
    //     {
    //       'menu_item_name': 'Campaign Listing',
    //       'link': '/accessory-master/campaign-listing',
    //
    //     }
    //   ]
    // },
    // {
    //   'menu_item_name': 'Used Car',
    //   'link': '/used-car',
    //   'img': 'assets/imgs/sidebar-icons/Used-Car.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Used-Car-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Car Category Listing',
    //       'link': '/used-car/car-category-listing',
    //
    //    },
    //     {
    //       'menu_item_name': 'Model Car Listing',
    //       'link': '/used-car/model-car-listing',
    //
    //     },
    //     {
    //       'menu_item_name': 'Specification Listing',
    //       'link': '/used-car/specification-listing',
    //
    //     },
    //     {
    //       'menu_item_name': 'Used Car Callback Listing',
    //       'link': '/used-car/used-car-callback-listing',
    //
    //     },
    //     {
    //       'menu_item_name': 'Used Car Enquiry Listing',
    //       'link': '/used-car/used-car-enquiry-listing',
    //
    //     },
    //     {
    //       'menu_item_name': 'Used Car Listing',
    //       'link': '/used-car/used-car-listing',
    //
    //     }
    //   ]
    // },
    {
      'menu_name': 'CPOV',
      'link': '/cpov',
      'img': 'assets/imgs/sidebar-icons/CPOV.svg',
      'img_active': 'assets/imgs/sidebar-icons/CPOV-w.svg',
      'menu_item_list': [
        {
          'menu_item_name': 'CPOV Car Listing',
          'link': '/cpov/cpov-car-listing',
        },
        {
          'menu_item_name': 'CPOV Specification Listing',
          'link': '/cpov/cpov-specification-listing',
        },
        {
          'menu_item_name': 'CPOV Test Drive Listing',
          'link': '/cpov/cpov-testdrive-listing',
        }
      ]
    },
    {
      'menu_name': 'Registered Customer',
      'img': 'assets/imgs/sidebar-icons/Registerd-customer.svg',
      'img_active': 'assets/imgs/sidebar-icons/Registerd-customer-w.svg',
      'link': '/registered-customer'
    },
    // {
    //   'menu_name': 'Registered Vehicle',
    //   'img': 'assets/imgs/sidebar-icons/Registered-Vehicle.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Registered-Vehicle-w.svg',
    //   'link': '/registered-vehicle'
    // },
    {
      'menu_name': 'Showroom Appointment Booking',
      'img': 'assets/imgs/sidebar-icons/Showroom-Appointment-Booking.svg',
      'img_active': 'assets/imgs/sidebar-icons/Showroom-Appointment-Booking-w.svg',
      'link': '/showroom-appointment-booking'
    },
    {
      'menu_name': 'Callback Request',
      'img': 'assets/imgs/sidebar-icons/Callback-request.svg',
      'img_active': 'assets/imgs/sidebar-icons/Callback-request-w.svg',
      'link': '/callback-request'
    },
    // {
    //   'menu_name': 'Get Quote',
    //   'img': 'assets/imgs/sidebar-icons/Get-quote.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Get-quote-w.svg',
    //   'link': '/get-quote'
    // },
    {
      'menu_name': 'News',
      'img': 'assets/imgs/sidebar-icons/news.svg',
      'img_active': 'assets/imgs/sidebar-icons/news-w.svg',
      'link': '/news'
    },
    // {
    //   'menu_name': 'Feedback Module',
    //   'img': 'assets/imgs/sidebar-icons/Feedbakc-module.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Feedbakc-module-w.svg',
    //   'link': '/feedback-module'
    // },
    // {
    //   'menu_name': 'Digital Wallet Report',
    //   'img': 'assets/imgs/sidebar-icons/digital-wallet-report.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/digital-wallet-report-w.svg',
    //   'link': '/digital-wallet-report'
    // },
    {
      'menu_name': 'On Off Master',
      'img': 'assets/imgs/sidebar-icons/onoff-master.svg',
      'img_active': 'assets/imgs/sidebar-icons/onoff-master-w.svg',
      'link': '/on-off-master'
    },
    // {
    //   'menu_name': 'Roadside Assistance Request',
    //   'img': 'assets/imgs/sidebar-icons/roadside-assistance-request.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/roadside-assistance-request-w.svg',
    //   'link': '/roadside-assistance-request'
    // },
    // {
    //   'menu_name': 'Body Shop Enquiry',
    //   'img': 'assets/imgs/sidebar-icons/BodyShop-Enquiry.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/BodyShop-Enquiry-w.svg',
    //   'link': '/bodyshop-enquiry'
    // },
    {
      'menu_name': 'Showroom & Service Center Master',
      'img': 'assets/imgs/sidebar-icons/showrool-service-center-master.svg',
      'img_active': 'assets/imgs/sidebar-icons/showrool-service-center-master-w.svg',
      'link': '/showroom-service-center-master'
    },
    {
      'menu_name': 'Offers',
      'link': '/offers',
      'img': 'assets/imgs/sidebar-icons/Offers.svg',
      'img_active': 'assets/imgs/sidebar-icons/Offers-w.svg',
      'menu_item_list': [
        {
          'menu_item_name': 'Offers Listing',
          'link': '/offers/offers-listing',
        },
        {
          'menu_item_name': 'Applied Offers Listing',
          'link': '/offers/applied-offer-listing',
        }
      ]
    },
    {
      'menu_name': 'CMS Management',
      'img': 'assets/imgs/sidebar-icons/CMS-Management.svg',
      'img_active': 'assets/imgs/sidebar-icons/CMS-Management-w.svg',
      'link': '/cms-management/cms-management-listing'
    },
    // {
    //   'menu_name': 'Utility',
    //   'link': '/utility',
    //   'img': 'assets/imgs/sidebar-icons/Utility.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Utility-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Global Color Setting',
    //       'link': '/utility/global-color-setting',
    //     },
    //     {
    //       'menu_item_name': 'Global Stock Setting',
    //       'link': '/utility/global-stock-setting',
    //     },
    //     {
    //       'menu_item_name': 'Autoline Status Mapping',
    //       'link': '/utility/autoline-status-mapping',
    //     },
    //     {
    //       'menu_item_name': 'Email Setup',
    //       'link': '/utility/email-setup',
    //     },
    //     {
    //       'menu_item_name': 'Notification Parameter Setting',
    //       'link': '/utility/notification-parameter-setting',
    //     },
    //     {
    //       'menu_item_name': 'Periodic Serice Import',
    //       'link': '/utility/periodic-service-import',
    //     },
    //     {
    //       'menu_item_name': 'Social Media Links',
    //       'link': '/utility/social-media-links',
    //     }
    //   ]
    // },
    // {
    //   'menu_name': 'Parts',
    //   'link': '/parts',
    //   'img': 'assets/imgs/sidebar-icons/Part.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Part-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Parts Request Listing',
    //       'link': '/parts/part-request-listing',
    //     },
    //     {
    //       'menu_item_name': 'Payment Report',
    //       'link': '/parts/payment-report',
    //     }
    //   ]
    // },
    // {
    //   'menu_name': 'Service Booking',
    //   'link': '/service-booking',
    //   'img': 'assets/imgs/sidebar-icons/Service-Booking.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Service-Booking-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Service Booking Listing',
    //       'link': '/service-booking/service-booking-listing',
    //     },
    //     {
    //       'menu_item_name': 'Payment Matrix',
    //       'link': '/service-booking/payment-matrix',
    //     },
    //     {
    //       'menu_item_name': 'Payment Report',
    //       'link': '/service-booking/payment-report',
    //     },
    //     {
    //       'menu_item_name': 'Service Type Listing',
    //       'link': '/service-booking/service-type-listing',
    //     },
    //     {
    //       'menu_item_name': 'Services Listing',
    //       'link': '/service-booking/services-listing',
    //     },
    //     {
    //       'menu_item_name': 'Serivce Slot Master',
    //       'link': '/service-booking/service-booking-master',
    //     }
    //   ]
    // },
    // {
    //   'menu_name': 'Magazine',
    //   'img': 'assets/imgs/sidebar-icons/Magazine.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Magazine-w.svg',
    //   'link': '/magazine'
    // },
    // {
    //   'menu_name': 'Merchandise',
    //   'link': '/merchandise',
    //   'img': 'assets/imgs/sidebar-icons/Merchandise.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Merchandise-w.svg',
    //   'menu_item_list': [
    //     {
    //       'menu_item_name': 'Merchandise Campaign Listing',
    //       'link': '/merchandise/merchandise-campaign-listing',
    //     },
    //     {
    //       'menu_item_name': 'Merchandise Category Listing',
    //       'link': '/merchandise/merchandise-category-listing',
    //     },
    //     {
    //       'menu_item_name': 'Merchandise Enquiry Listing',
    //       'link': '/merchandise/merchandise-enquiry-listing',
    //     },
    //     {
    //       'menu_item_name': 'Merchandise Order Listing',
    //       'link': '/merchandise/merchandise-order-listing',
    //     },
    //     {
    //       'menu_item_name': 'Merchandise Stock Listing',
    //       'link': '/merchandise/merchandise-stock-listing',
    //     },
    //     {
    //       'menu_item_name': 'Merchandise Payment Matrix',
    //       'link': '/merchandise/merchandise-payment-matrix',
    //     }
    //   ]
    // },
    // {
    //   'menu_name': 'FAQ',
    //   'img': 'assets/imgs/sidebar-icons/Merchandise.svg',
    //   'img_active': 'assets/imgs/sidebar-icons/Merchandise-w.svg',
    //   'link': '/faq/faq-listing'
    // }
  ];

  constructor(
    private router: Router,
    public domSanitizer: DomSanitizer,
    private commonService: CommonService,
    private httpRequestService: HttpRequestService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
   };
   this.envOrigin = environment.origin;
  }

  public getSafeUrl(url: string): any {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * Get User Roles For Current Page
   */
  private getUserRolesForCurrentPage(): void {
    // Unsubscribe previous api call
    if (this.getUserRolesForCurrentPageSubscription) {
      this.getUserRolesForCurrentPageSubscription.unsubscribe();
    }
    let page_id;
    for (const obj of this.menu) {
      if (obj.link && this.currentUserPath && obj.link === this.currentUserPath) {
        page_id = obj.menu_id;
        break;
      }
      if (obj.link && this.currentRoute && this.currentUserPath === '' && obj.link === this.currentRoute) {
        page_id = obj.menu_id;
        break;
      }

      if (obj.menu_item_list) {
        for (const inner of obj.menu_item_list) {
          if (inner.link && this.currentUserPath && inner.link === this.currentUserPath) {
            page_id = inner.menu_item_id;
            break;
          }
        }
      }
    }

    if (page_id) {
      const params = { page_id: page_id };
      // Call the offer list from API
      this.getUserRolesForCurrentPageSubscription = this.httpRequestService.httpRequest('get', 'user/getpageroles', params).subscribe(
        (response: HttpResponse<any>) => {
          if (response['code'] === 200) {
            this.commonService.setEvent('user_page_roles_updated', response['data']);
            localStorage.setItem('user_page_roles_updated', JSON.stringify(response['data']));
          }
          this.getUserRolesForCurrentPageSubscription = undefined;
        },
        (error: HttpResponse<any>) => {
          console.error(error);
          this.getUserRolesForCurrentPageSubscription = undefined;
        }
      );
    }
  }

  /**
   * Toggle SubMenu
   * @param data Check if menu has sub-menu
   */
  public toggleSubMenu(data) {
    if (data.hasSubMenu) {
      data.hasSubMenu = false;
    } else {
      data.hasSubMenu = true;
    }
    for (const obj of this.menu) {
      if (obj.menu_item_name !== data.menu_item_name) {
        obj['hasSubMenu'] = false;
      }
    }
  }

  public calculateRoute(): void {
    for (const obj of this.menu) {
      if (obj.menu_item_list !== undefined) {
        for (const innerMenu of obj.menu_item_list) {
          if (innerMenu.link === this.router.url) {
            obj['hasSubMenu'] = true;
            obj['isActive'] = true;
            this.currentUserPath = innerMenu.link;
            break;
          } else {
            obj['isActive'] = false;
            obj['hasSubMenu'] = false;
          }
        }
      } else {
        if (obj.link && obj.link === this.router.url) {
          obj['isActive'] = true;
        }
        if (obj.link && obj.link !== this.router.url) {
          obj['isActive'] = false;
        }
      }
    }
  }

  /**
    * Angular Lifecycle Event For Component Initialization
    */
  public ngOnInit(): void {
    const menuData = localStorage.getItem('sidebarConfig');
    if (menuData) {
      this.menu = JSON.parse(menuData);
    }
    this.calculateRoute();
    this.getUserRolesForCurrentPage();
    // console.log(this.router.url);
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentUserPath = this.router.url;
          setTimeout(() => {
            // trick the Router into believing it's last link wasn't previously loaded
            this.router.navigated = false;
            window.scrollTo(0, 0);
          }, 500);
          this.calculateRoute();
          this.getUserRolesForCurrentPage();
        }
      }
    );
  }

  /**
   * Angular Lifecycle Event For Component Initialization
   */
  public ngOnDestroy(): void {
    if (this.getUserRolesForCurrentPageSubscription !== undefined) {
      this.getUserRolesForCurrentPageSubscription.unsubscribe();
    }
  }
}

